import { Injectable, OnDestroy } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { Store } from '@ngrx/store';
import { AppState } from '../..';
import { SetSelectedeventItem, SetCorrelatedEventItem } from '../../store/event/_actions';
import { environment } from 'src/environments/environment.prod';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../base.service';
import { SettingsService } from '../../settings';
import { BehaviorSubject, Observable } from 'rxjs';
import { EventServiceConnection, EventServiceConnectionState } from './event.models';

@Injectable({
  providedIn: 'root'
})
export class EventService extends BaseService implements OnDestroy {
  private retries = 0;
  private partyId = '';
  private connectionUrl = '';
  private signalRConnection: signalR.HubConnection;
  private _connection = new BehaviorSubject<EventServiceConnection>(new EventServiceConnection());
  connection: Observable<EventServiceConnection>;
  baseUrl: string;
  subKey: string;
  apiVersion: string;
  private _stoppedGracefully: boolean = false;
  accessToken: any;


  constructor(
    httpClient: HttpClient,
    settingsService: SettingsService,
    private store: Store<AppState>) {
    super(httpClient, settingsService);
    this.baseUrl = settingsService.getSettings().baseUrl;
    this.subKey = settingsService.getSettings().apiKey;
    this.apiVersion = settingsService.getSettings().apiVersion;
    this._connection.subscribe(con => {
      console.debug('Connection details:', '\n', con);
    })
  }
  ngOnDestroy(): void {
    this.stop();
  }

  private delay = (retryCount: number, con: EventServiceConnection) => {
    const delaySeconds = 2 ** retryCount;
    con.nextConnectionAttemptInSeconds = delaySeconds;
    this._connection.next(con);
    return new Promise(resolve => setTimeout(resolve, delaySeconds));
  }

  start(partyId: string): Promise<void> {
    this.partyId = partyId;
    return new Promise<void>((resolve, reject) => {
      if (!this.accessToken || !this.connectionUrl)
        this.NegotiateConnection(this.partyId).then(resolve, reject);
      else
        this.startConnection().then(resolve, reject);
    });
  }

  public async NegotiateConnection(partyId: string, retryCount: number = 0): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this._connection.next(new EventServiceConnection(EventServiceConnectionState.CONNECTING, 'Negotiating connection'));
      this.post(`${this.baseUrl}lightstone-messagebus-api/${this.apiVersion}/pushMessages/negotiate?partyId=${partyId}`, {})
        .subscribe(
          x => {
            this._connection.next(new EventServiceConnection(EventServiceConnectionState.CONNECTING, 'Connection negotiated. Connecting'));
            this.accessToken = x.body.accessToken;
            this.connectionUrl = x.body.url;
            this.connectToEventService().then(resolve, reject);
          },
          error => {
            let con = new EventServiceConnection(EventServiceConnectionState.ERROR, 'Could not negotiate connection with the event service.');
            con.error = error;
            this._connection.next(con);
            this.delay(retryCount, con).then(() => this.NegotiateConnection(partyId, retryCount + 1).then(resolve, reject));
          }
        );
    });
  }

  private async connectToEventService(): Promise<void> {

    this.createConnection();
    return this.startConnection();
  }

  private registerOnServerEvents() {
    // all messages
    this.signalRConnection.on('Broadcast', (data: any) => {
      this.store.dispatch(new SetSelectedeventItem({ event: data }));
    });
    // all messages to group party id not connection id
    this.signalRConnection.on('SendMessageToOthersInGroup', (data: any) => {
      this.store.dispatch(new SetSelectedeventItem({ event: data }));
    });
    // all messages to partyid
    this.signalRConnection.on('SendMessageToGroup', (data: any) => {
      console.log(data);
      if (data.correlationId) {
        this.store.dispatch(new SetCorrelatedEventItem({ event: data }));
      } else {
        this.store.dispatch(new SetSelectedeventItem({ event: data }));
      }
    });
  }

  private startConnection(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this._connection.next(new EventServiceConnection(EventServiceConnectionState.CONNECTING, 'Starting connection to event service'));

      this.signalRConnection.start().then(() => {
        this._connection.next(new EventServiceConnection(EventServiceConnectionState.CONNECTED, 'Succesfully connected to event service'));
        this.retries = 0;
        if (!environment.production) {
          console.log(this.signalRConnection);
        }
        resolve();
      }).catch((err) => {
        this._connection.next(new EventServiceConnection(EventServiceConnectionState.ERROR, err));
        delete(this.accessToken);
        this.connectionUrl = null;
        if (this.retries < 3) {
          this.retries++;
          this.signalRConnection = null;
          return this.start(this.partyId);
        } else {
          
          this._connection.next(new EventServiceConnection(EventServiceConnectionState.DISCONNECTED, 'Gave up retrying to conntect'));
          reject();
        }
      });
    });
  }

  private createConnection() {
    if (this.signalRConnection)
      return;
    this._connection.next(new EventServiceConnection(EventServiceConnectionState.CONNECTING, 'Creating connection'));
    this.signalRConnection = new signalR.HubConnectionBuilder()
      .withUrl(this.connectionUrl, {
        accessTokenFactory: () => this.accessToken,
        withCredentials: false
      })
      .build();
    this.signalRConnection.onclose(error => {
      let errorConnection = new EventServiceConnection(this._stoppedGracefully ? EventServiceConnectionState.DISCONNECTED : EventServiceConnectionState.ERROR, 'Connection to event service closed');
      errorConnection.error = error;
      this._connection.next(errorConnection);
      if (!this._stoppedGracefully) {
        this.delay(this.retries, errorConnection).then(() => this.startConnection());
      }
    });
    this.registerOnServerEvents();

  }

  async stop() {
    this._stoppedGracefully = true;
    delete(this.accessToken);
    if (this.signalRConnection) {
      await this.signalRConnection.stop();
      this._connection.next(new EventServiceConnection(EventServiceConnectionState.DISCONNECTED, 'Succesfully disconnected'));
      this._stoppedGracefully = false;
    }
  }

}
