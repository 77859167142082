import { Component, OnInit, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ShareService } from 'src/app/core/services/share/share.service';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss']
})
export class ShareComponent implements OnInit {
  modalOptions: NgbModalOptions;
  @ViewChild('shareUsers', { static: false }) shareTemplate: ElementRef;
  shareToForm: FormGroup;
  share = false;
  shareSending = false;
  sharedone = false;
  statusmessage = '';
  emailList: Array<string> = [];
  refreshchild = false;
  shareSuccess: EventEmitter<boolean> = new EventEmitter();
  html: string;

  constructor(
    private modalService: NgbModal,
    private formbuilder: FormBuilder,
    private shareService: ShareService) {
    this.modalOptions = {
      backdrop: 'static',
      size: 'lg',
      backdropClass: 'customBackdrop'
    };

  }

  get f() { return this.shareToForm.controls; }

  open(content) {
    this.modalService.dismissAll();
    this.sharedone = false;
    this.modalService.open(content, this.modalOptions).result.then((result) => {
      return result;
    }, (result) => {
      return result;
    });
  }

  
  ngOnInit(): void {
    this.shareToForm = this.formbuilder.group({
      message: [' I thought you might find this interesting', Validators.required],
      mailTo: ['', '']
    });

    this.shareToForm.controls.mailTo.valueChanges.subscribe((res) => {
      if (res != null) {
        if (res.indexOf(';') !== -1) {
          this.addEmail();
        }
      }

    });

    setTimeout(() => {
      this.open(this.shareTemplate);
    }, 0);
  }

  addEmail() {

    const email = this.shareToForm.controls.mailTo.value;

    if (email.indexOf(';') !== -1) {
      const arr = email.split(';');
      arr.forEach(element => {
        if (element.length !== 0) {
          this.emailList.push(element.trim());
        }
      });

    } else {
      this.emailList.push(email.trim());
    }

    this.shareToForm.controls.mailTo.reset();

  }

  remEmail(val) {
    const ix = this.emailList.indexOf(val);
    this.emailList.splice(ix, 1);
    return false;
  }

  async shareReport(): Promise<void>{
    if(this.shareToForm.controls.mailTo.value != "" && this.shareToForm.controls.mailTo.value != null)
    {
      this.addEmail();
    }
    if(this.emailList.length!=0)
    {

      this.shareSending = true;
      this.html = this.webComponentHTML();

      var productflowid = window.document.location.href.split('/')[window.document.location.href.split('/').length -1];
  
      console.log(productflowid);

      (await this.shareService.shareReport(productflowid, this.emailList, this.html, this.shareToForm.controls.message.value
        )).subscribe((a)=>{
          this.sharedone = true;
          this.shareSending = false;
        });
       
    }else{
      this.statusmessage = "Please add at least one email address";
    }

  }

  private webComponentHTML(): string {
    let webComponentContainers = document.getElementsByClassName('web-component-container');
    var webComponent: Node;
    if (webComponentContainers.length && webComponentContainers[0].hasChildNodes()) {
        webComponent = webComponentContainers[0].firstChild.cloneNode();
    }
    if (!webComponent)
        return null;
    let styles = Array.from(document.querySelectorAll('link[data-ls-type="style-ref"]'));

    let webComponentScripts = Array.from(document.querySelectorAll('script[data-ls-type="web-component"]'));
    let window = document.defaultView as any;

    return `<html><head>
    <script>
    (function(open, fetch) {
        XMLHttpRequest.prototype.open = function(method, url, async, user, password){
    
            open.apply(this, arguments);
            var headers = getHeaderValues(url);
            if (headers.length > 0){
                this.setRequestHeader('Authorization', headers[0].Authorization);
            }
        };
    
        window.fetch = function() {
          var url = arguments[0];

          if(url.indexOf('google.com')===-1)
          {
              var headers = getHeaderValues(url);
              if (headers.length > 0){
                  arguments[1].headers = arguments[1].headers || {};
                  arguments[1].headers.Authorization = headers[0].Authorization;
                  arguments[1].headers["Ocp-Apim-Subscription-Key"] = headers[0].Key;
              }
              return fetch.apply(this, arguments)
          }else{
              return fetch(url);
          }
      }
    
        function getHeaderValues(url){
            if (url.indexOf('http') == 0) {
                var urlObj = new URL(url);
                if (urlObj.hostname.includes("apis.lightstone.co.za"))
                {
                    var bearerToken = "` + localStorage["msal.idtoken"] + `";
                    var key = "` + (window.jupiter ? window.jupiter.ocpApimSubscriptionKey :  "") + `"
                    return [{
                        Authorization: 'Bearer ' + bearerToken,
                        Key : key
                     }]
                }
            }
            return [];
        }
    
    })(XMLHttpRequest.prototype.open, window.fetch);
    </script>` +
        styles.map(script => {
            return `<link href="` + script.getAttribute('href') + `" type="` + script.getAttribute('type') + `" rel="` + script.getAttribute('rel') + `" />`;
        }).join('') +
        webComponentScripts.map(script => {
            return `<script src="` + script.getAttribute('src') + `" type="text/javascript" id="` + script.getAttribute('id') + `"></script>`;
        }).join('') +
        `</head><body>` + (webComponent as Element).outerHTML + `</body></html>`;
}

}
