import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/core';
import { AcceptInviteService } from 'src/app/core/services/accept-invite/accept-invite.service';

@Component({
  selector: 'ls-accept-invite',
  template: ``
})
export class AcceptInviteComponent implements OnInit {
  constructor(private store: Store<AppState>, private route: ActivatedRoute, private router: Router, private acceptInviteService: AcceptInviteService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(param => {
      localStorage.setItem('acceptInviteToken', param.token);
      if(param.email){
        localStorage.setItem('hint_email', param.email);
      }
      this.router.navigate(['']);
    });
  }

}
