// Actions
import { eventActions, eventActionTypes } from './_actions';
import { IEventModel } from '../../models/event.model';

// Models
export interface EventState {
  currentEvent: IEventModel;
  correlationId: string;
  eventStack: IEventModel[];
  correlatedEvent: IEventModel;
}

export const initialeventState: EventState = {
  currentEvent: null,
  correlationId: '',
  eventStack: [],
  correlatedEvent: null
};

export function eventReducer(state = initialeventState, action: eventActions): EventState {
  switch (action.type) {
    case eventActionTypes.SetSelectedEventItem: {
      return {
        ...state,
        currentEvent: action.payload.event
      };
    }
    case eventActionTypes.SetCorrelatedEventItem: {
      return {
        ...state,
        correlatedEvent: action.payload.event,
        correlationId: action.payload.event.correlationId
      };
    } case eventActionTypes.ClearEvent: {
      return initialeventState;
    }
    case eventActionTypes.SetCorrelationId: {
      return {
        ...state,
        correlationId: action.payload.correlationId
      };
    }
    default: {
      return state;
    }
  }
}
