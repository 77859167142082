import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SettingsService } from '../../settings';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class OnboardingService extends BaseService {
  constructor(httpClient: HttpClient, protected settingService: SettingsService) {
    super(httpClient, settingService);
  }

  protected getHeaders(): HttpHeaders {

    const key = this.settingService.getSettings().apiKey;
    const tenantId = this.settingService.getSettings().tenantId;

    let headers = new HttpHeaders();
      headers = headers.set('Ocp-Apim-Subscription-Key', key);
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('x-Authenticated-TenantId', tenantId);
    return headers;
  }

  isOnboardingComplete(): Observable<boolean> {
    return this.httpClient.get<boolean>(this.settingsService.getSettings().baseUrl +
      `customer-management/${this.settingService.getSettings().apiVersion}/customer/HasCompletedOnboarding`,
      {
        headers: this.getHeaders()
      })
      .pipe(map(r => {
        return r;
      }));
  }

 
}
