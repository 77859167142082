import { fromEvent, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Injector } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OnsellProgressComponent } from '../onsell-progress.component';
import { IProductEventService, ProductEvent } from '../../models/productEvent.model';
import { SettingsService } from 'src/app/core/settings';
import { IProductFlow } from 'src/app/core/models/productFlow.model';
import { ProductFlowWorkflowService } from 'src/app/core/services/workflow/productflow.workflow.service';
import { Router } from '@angular/router';

export class OnsellEventService implements IProductEventService {
    private sub: Subscription;
    private document: Document;
    private modalService: NgbModal;
    private workflowService: ProductFlowWorkflowService;
    router: Router;
    constructor(
        private injector: Injector, protected settingsService: SettingsService) {
        this.document = this.injector.get(DOCUMENT);
        this.modalService = this.injector.get(NgbModal);
        this.workflowService = this.injector.get(ProductFlowWorkflowService);
        this.router = this.injector.get(Router);
    }

    init(): void {
        this.sub = fromEvent<ProductEvent>(this.document, 'ls-onsell-clicked').pipe(filter(e => e.type === 'ls-onsell-clicked')).subscribe((e) => {
            let context = e.detail.product.contexts.length < 1 ? null : e.detail.product.contexts[0];
            if (!context && !e.detail.product.productCode) {
                console.warn('No product contexts in ls-onsell-clicked event. Not continuing...');
                return;
            }

            if(!context){
                const crypto = window.crypto;
                let array = new Uint32Array(1);
                
                context ={
                    id: crypto.getRandomValues(array).toString(),
                    context:''
                };
                this.perfomOnSell(context, e);


            }else{
                this.perfomOnSell(context, e);
            }
         
        });
    }
    destroy(): void {
        this.sub.unsubscribe();
    }

    perfomOnSell(context, e){
        const modalRef = this.modalService.open(OnsellProgressComponent);
        const productFlow = {
            contextId: context.id,
            context: context.context,
            productCode: e.detail.product.productCode,
            source: this.stripCad(e.detail.source)
        } as IProductFlow;
        this.workflowService.startProductFlow(productFlow).subscribe(
            s => {
                this.router.navigateByUrl('/', { skipLocationChange: true })
                    .then(() => {
                        this.router.navigate([`product`, s.body.productFlowInstanceId]);
                        modalRef.close();
                    })
            },
            (err) => {
                console.error(err);
                modalRef.close();
            }
        );

    }

    stripCad(source) {
        if(source==null) {
            return '';
        } else{
            let json = JSON.parse(source);
            if(json.cadPolygon)
            {
                json.cadPolygon = null;
            }
            return JSON.stringify(json);
        }
    }
}
