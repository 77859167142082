import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of, pipe } from 'rxjs';
import { Action, Store, select } from '@ngrx/store';
import { mergeMap, map, catchError, switchMap, tap } from 'rxjs/operators';
import { AcceptInvite, SetAcceptInviteResponse,AcceptInviteActionTypes, AcceptInviteError } from './_actions';
import { AcceptInviteService } from '../../services/accept-invite/accept-invite.service';
import { AcceptInviteResponseModel } from '../../models/acceptInviteResponse.model';

@Injectable()
export class AcceptInviteEffects {

    constructor(private actions$: Actions, private acceptInviteService: AcceptInviteService) {
    }
    
    acceptInvite$ = createEffect(() => this.actions$.pipe(
        ofType(AcceptInviteActionTypes.AcceptInvite),
        switchMap((token: string) =>
        
            this.acceptInviteService.acceptInvite(token).pipe(
                map((response: AcceptInviteResponseModel) => {
                    return new SetAcceptInviteResponse({ acceptInviteResponse: response });
                }), catchError(err => of(new AcceptInviteError({error: err})))
                , catchError(r =>
                    of(r)
                )
            )
        )));
}
