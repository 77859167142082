import { IDownloadService, DownloadEvent } from '../download.models';
import { fromEvent, Subscription, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PDFDownloadProgressComponent } from './pdf-download-progress-component';
import { SettingsService } from '../../settings';

export class PdfDownloadService implements IDownloadService {
    private sub: Subscription;
    private pdfServerUrl: string;
    private httpClient: HttpClient;
    private document: Document;
    private modalService: NgbModal;
    private pdfOptions: any;

    constructor(
        private injector: Injector, protected settingsService: SettingsService
    ) {
        this.httpClient = this.injector.get(HttpClient);
        this.document = this.injector.get(DOCUMENT);
        this.modalService = this.injector.get(NgbModal);
        this.pdfServerUrl = this.settingsService.getSettings().pdfUrl;
    }

    private getOptions():string{
        if (this.pdfOptions){
            return JSON.stringify(this.pdfOptions);
        }
        return "";
    }

    public HTMLtoPDF(html: string): Observable<Blob> {
        const formData = new FormData();
        formData.append('html', html);

        const options = this.getOptions();
        formData.append('options', options);

        let url = this.pdfServerUrl + "?n=" + Math.random();

        return this.httpClient.post(url, formData, { responseType: 'blob' });
    }

    private webComponentHTML(): string {
        let webComponentContainers = document.getElementsByClassName('web-component-container');
        let webComponent: Node;
        if (webComponentContainers.length && webComponentContainers[0].hasChildNodes()) {
            webComponent = webComponentContainers[0].firstChild.cloneNode();
        }
        if (!webComponent)
            return null;
        let styles = Array.from(document.querySelectorAll('link[data-ls-type="style-ref"]'));

        let webComponentScripts = Array.from(document.querySelectorAll('script[data-ls-type="web-component"]'));
        let window = document.defaultView as any;

        let docHtml = `
        <!doctype html>
        <html><head>
        <script>
        (function(open, fetch) {
            XMLHttpRequest.prototype.open = function(method, url, async, user, password){
        
                open.apply(this, arguments);
                let headers = getHeaderValues(url);
                if (headers.length > 0){
                    this.setRequestHeader('Authorization', headers[0].Authorization);
                }
            };
        
            window.fetch = function() {
                let url = arguments[0];

                if(url.indexOf('google.com')===-1)
                {
                    let headers = getHeaderValues(url);
                    if (headers.length > 0){
                        arguments[1].headers = arguments[1].headers || {};
                        arguments[1].headers.Authorization = headers[0].Authorization;
                        arguments[1].headers["Ocp-Apim-Subscription-Key"] = headers[0].Key;
                    }
                    return fetch.apply(this, arguments)
                }else{
                    return fetch(url);
                }
            }
        
            function getHeaderValues(url){
                if (url.indexOf('http') == 0) {
                    let urlObj = new URL(url);
                    if (urlObj.hostname.includes("apis.lightstone.co.za"))
                    {
                        let bearerToken = "` + localStorage["msal.idtoken"] + `";
                        let key = "` + (window.jupiter ? window.jupiter.ocpApimSubscriptionKey :  "") + `"
                        return [{
                            Authorization: 'Bearer ' + bearerToken,
                            Key : key
                         }]
                    }
                }
                return [];
            }
        
        })(XMLHttpRequest.prototype.open, window.fetch);
        </script>` +
            styles.map(script => {
                return `<link href="` + script.getAttribute('href') + `" type="` + script.getAttribute('type') + `" rel="` + script.getAttribute('rel') + `" />`;
            }).join('') +
            webComponentScripts.map(script => {
                return `<script src="` + script.getAttribute('src') + `" type="text/javascript" id="` + script.getAttribute('id') + `"></script>`;
            }).join('') +
            `</head><body>` + (webComponent as Element).outerHTML + `</body></html>`;
           
        return docHtml;
    }

    init(): void {
        this.sub = fromEvent<DownloadEvent>(this.document, 'download').pipe(filter(e => e?.detail?.type === 'pdf')).subscribe((e) => {
            let customFile;
            if (e?.detail?.options){
                if (e.detail.options?.filename){
                    const checkLength = e.detail.options.filename?.trim().length;
                    if (checkLength > 2){
                        customFile = e.detail.options.filename + ".pdf";
                    }                    
                }
                this.pdfOptions = e.detail.options;
            }

            const modalRef = this.modalService.open(PDFDownloadProgressComponent);
            this.HTMLtoPDF(this.webComponentHTML()).subscribe({
                next: blob => {
                    let newBlob = new Blob([blob], { type: "application/pdf" })
                    const data = window.URL.createObjectURL(newBlob);
                    let link = document.createElement('a');
                    link.href = data;
                    let filename = new Date().toISOString().slice(-24).replace(/\D/g,'').slice(0, 14) + ".pdf";
                    if (customFile){
                        filename = customFile;
                    }
                    link.download = filename;
                    link.click();
                    setTimeout(function () {
                        // For Firefox it is necessary to delay revoking the ObjectURL
                        window.URL.revokeObjectURL(data);
                        modalRef.close();
                    }, 100);
                }, error: error => {
                    console.error(error);
                    modalRef.close();
                }
            });
        });
    }
    destroy(): void {
        this.sub.unsubscribe();
    }
}