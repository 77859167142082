import { MonitoringService } from './monitoring.service';
import { Injectable, ErrorHandler } from '@angular/core';

@Injectable()
export class ErrorHandlerService extends ErrorHandler {

    constructor(private monitoringService: MonitoringService) {
        super();
    }

    handleError(error: Error) {
        this.monitoringService.logException(error);
    }
}