import { Injectable, Renderer2 } from '@angular/core';
import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from '../../settings';
import { BaseService } from '../base.service';
import { IProductFlowInstance } from '../../product/models/productFlowInstance.model';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { Referral } from 'src/referral/models/referral.model';
import { Crypto } from "@coaxle/crypto";  

interface IScript {
  src: string;
  name: string;
  loaded: boolean;
  status: string;
}

class WrappedSecret {
  secret: SecretModel;
}
class SecretModel {
  secret: string;
  key: string;
  iv: string;
}
@Injectable({
  providedIn: 'root'
})
export class WebComponentLoaderService extends BaseService {
  private scripts: IScript[] = [];
  public renderer: Renderer2;
  baseUrl: string;
  apiVersion: string;
  constructor(httpClient: HttpClient, protected settingsService: SettingsService) {
    super(httpClient, settingsService);
    this.baseUrl = settingsService.getSettings().baseUrl;
    this.apiVersion = settingsService.getSettings().apiVersion;
  }

  public addScript(scriptName: string, scriptSrc: string): boolean {
    if (_.findIndex(this.scripts, { src: scriptSrc }) === -1) {
      this.scripts.push({ src: scriptSrc, name: scriptName, loaded: false } as IScript);
      return true;
    }
    return false;
  }

  public loadScript(scriptName: string, scriptSrc: string): Promise<IScript> {
    return new Promise((resolve, reject) => {
      const scriptIndex = _.findIndex(this.scripts, { src: scriptSrc });
      const script = this.scripts[scriptIndex];
      const customerRegistryElement = window.customElements;
      const elem = customerRegistryElement.get(scriptName);
      if ((script && !script.loaded) || elem === undefined) {
        const node = this.renderer.createElement('script');
        node.src = script.src;
        node.type = 'text/javascript';
        node.async = false;
        node.charset = 'utf-8';
        node.id = script.name;
        node.setAttribute('data-ls-type', 'web-component');
        if (node.readyState) {  //IE
          node.onreadystatechange = () => {
            if (node.readyState === "loaded" || node.readyState === "complete") {
              node.onreadystatechange = null;
              script.loaded = true;
              resolve({ name : scriptName, src: scriptSrc, loaded: true, status: 'Loaded' });
            }
          };
        } else {  //Others
          node.onload = () => {
            script.loaded = true;
            resolve({ name : scriptName, src: scriptSrc, loaded: true, status: 'Loaded' });
          };
        }
        node.onerror = (error: any) => resolve({name : scriptName, src: scriptSrc, loaded: false, status: 'Loading failed'});
        document.getElementsByTagName('head')[0].appendChild(node);
      }
      else {
        resolve({ name : scriptName, src: scriptSrc, loaded: true, status: 'Already Loaded' });
      }
    });
  }


  public removeScript(scriptName: string, scriptSrc: string) {
    const elem = document.getElementById(scriptName);
    if (elem && elem.attributes.getNamedItem('src').value === scriptSrc) {
      this.scripts = this.scripts.filter(a=>a.name!=scriptName);
      this.renderer.removeChild(document.getElementsByTagName('head')[0], elem);
    }
  }

  public getProductFlow(id: any): Observable<IProductFlowInstance> {
    return this.get(`${this.baseUrl}lightstone-productmanagement-webapi/${this.apiVersion}/Flows/${id}`)
      .pipe(map(res => {
        return res.body;
      }
      ));
  }
  public getReferral(id: any): Observable<Referral> {
    return this.get(`${this.baseUrl}lightstone-referralmanagement-api/${this.apiVersion}/Referral/${id}`)
      .pipe(map(res => {
        return res.body;
      }
      ));
  }

  public async decryptProductFlow(productFlowId: string, encrypted: any): Promise<any> {
    let data: any = encrypted;

    if (typeof encrypted === "string")
    try {
        data = JSON.parse(encrypted);
    }
    catch {
        return encrypted;
    }

    if (!data.alg) {
        return encrypted;
    }

    let secret :SecretModel;

    try{
      secret = await this.getSecret(productFlowId);
    }catch{
      secret = null;
    }
    
    
    if (secret == null){
      return JSON.stringify({ Result: "Secret Expired"});
    }

    let retVal = Crypto.decrypt(data, secret.key, secret.iv);
    return JSON.parse(retVal);
}

  private async getSecret(productFlowId: string): Promise<SecretModel> {
    const url = `${this.baseUrl}crypto-api/${this.apiVersion}/vault/secret/${productFlowId}`
    const wrappedSecret: any = await this.get(url).toPromise();

    const deserializedSecret: SecretModel = JSON.parse(wrappedSecret.body.secret);
    return deserializedSecret;

  }
}
