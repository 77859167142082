import { Injectable, Injector } from '@angular/core';
import { SettingsService } from '../settings';
import { IProductEventService } from './models/productEvent.model';
import { OnsellEventService } from './onsell/service/onsell.service';
@Injectable({
    providedIn: 'root',
  })
export class ProductEventFactory {


    constructor(private injector: Injector,  protected settingsService: SettingsService){

    }
    public init():void {
        this.productEventServices.forEach((service) => service.init());
    }

    public destroy(): void {
        this.productEventServices.forEach((service) => service.destroy());
    }
    private _productEventServices = [new OnsellEventService(this.injector, this.settingsService)];
    public get productEventServices(): IProductEventService[] {
        return this._productEventServices;
    } 
}