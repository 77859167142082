
import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ls-pdf-download-progress',
  templateUrl: './pdf-download-progress.component.html',
  styleUrls: ['./pdf-download-progress.component.scss']
})
export class PDFDownloadProgressComponent {
  @Input() message: string;
  @Input() title: string;

  constructor(public activeModal: NgbActiveModal) {}
}