import { IDownloadService, DownloadEvent } from '../download.models';
import { fromEvent, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { SettingsService } from '../../settings';
import { filter } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ShareComponent } from 'src/app/partials/share/share.component';

export class ShareDownloadService implements IDownloadService {
    private sub: Subscription;
    private excelServerUrl: string;
    headers:any;
    private httpClient: HttpClient;
    private document: Document;
    private shareComponent: ShareComponent;
    private modalService: NgbModal;
    constructor(
        private injector: Injector, protected settingsService: SettingsService
    ) {
        this.httpClient = this.injector.get(HttpClient);
        this.document = this.injector.get(DOCUMENT);
        this.modalService = this.injector.get(NgbModal);
    }

    init(): void {
        this.sub = fromEvent<DownloadEvent>(this.document, 'share').pipe(filter(e => e.detail && e.detail.type && e.detail.type === 'share')).subscribe((e) => {
            this.modalService.open(ShareComponent);
        });
    }
    destroy(): void {
        this.sub.unsubscribe();
    }
}