import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { SettingsService } from '../../settings';
import { IProductFlow } from '../../models/productFlow.model';
import { Observable } from 'rxjs';
import { IWorkflowService } from './workflowservice.models';


@Injectable({
  providedIn: 'root'
})
export class ProductFlowWorkflowService extends BaseService implements IWorkflowService {

  constructor(httpClient: HttpClient, protected settingService: SettingsService) {
    super(httpClient, settingService);
  }

  public startProductFlow(productFlow: IProductFlow): Observable<HttpResponse<any>> {
    if(productFlow.source.length > 0) {
      try {
        var source = JSON.parse(productFlow.source);
        delete source["cadPolygon"]
        productFlow.source = JSON.stringify(source);
      }
      catch {
        console.log("Could not extract cadPolygon");
      }
    }

    return this.post(
      // tslint:disable-next-line: max-line-length
      this.settingService.getSettings().baseUrl + 'lightstone-productmanagement-webapi/' + this.settingService.getSettings().apiVersion + '/Flows/Start',
      { contextId: productFlow.contextId, context: productFlow.context, productCode: productFlow.productCode, source: productFlow.source },
      '');
  }

  public poll(location): Observable<HttpResponse<any>> {
    return this.get(location, null, null);
  }

  public updateData(flowId: string, partyId: string, data: string, stopPropogation: boolean): Observable<HttpResponse<any>> {
    return this.post(
      this.settingService.getSettings().baseUrl + 'lightstone-productmanagement-webapi/' +
      this.settingService.getSettings().apiVersion + '/Flows/' + flowId + '/Update/OutputData?partyId=' + partyId + '&stopPropogation=' + stopPropogation, {
        Data: JSON.stringify(data)
      }, '');
  }

  public completeInput(flowId: string, data: string, productCode?: string): Observable<HttpResponse<any>> {
    return this.post(
      this.settingService.getSettings().baseUrl + 'lightstone-productmanagement-webapi/' +
      this.settingService.getSettings().apiVersion + '/Flows/' + flowId + '/Input/Complete' + (productCode ? ('?productCode=' + productCode) : ''), {
        Data: JSON.stringify(data)
      }, '');
  }
}
