import { ProductHistoryModel } from '../../product/models/product-history.model';
import { AcceptInviteActions, AcceptInviteActionTypes } from './_actions';
import { AcceptInviteResponseModel } from '../../models/acceptInviteResponse.model';

export interface AcceptInviteState {
  acceptInviteResponse: AcceptInviteResponseModel;
  successResponse: AcceptInviteResponseModel;
  errorResponse: any;
}

export const initialAcceptInviteState: AcceptInviteState = {
  acceptInviteResponse: null,
  successResponse: null,
  errorResponse: null
};

export function acceptInviteReducer(state = initialAcceptInviteState, action: AcceptInviteActions): AcceptInviteState {
  switch (action.type) {
    case AcceptInviteActionTypes.SetAcceptInviteResponse: {
      return {
        ...state,
        acceptInviteResponse: action.payload.acceptInviteResponse
    };
  }
  case AcceptInviteActionTypes.AcceptInvite: {
    return{
      ...state
    };
  }
  case AcceptInviteActionTypes.AcceptInviteSuccess: {
    return{
      ...state,
      acceptInviteResponse: action.payload.response,
      successResponse: action.payload.response
    };
  }
  case AcceptInviteActionTypes.AcceptInviteError: {
    return{
      ...state,
      errorResponse: action.payload.error
    };
  }
  case AcceptInviteActionTypes.ClearInvite: {
    return{
      ...state,
      acceptInviteResponse: null
    };
  }
    default: {
      return state;
    }
  }
}
