import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { SettingsService } from '../../settings';
import { Observable } from 'rxjs';
import { ShareModel } from '../../models/share.model'
import { map } from 'rxjs/operators';
import { CustomerManagementService } from '@lightstone/onboarding';

@Injectable({
  providedIn: 'root'
})
export class ShareService extends BaseService {
  constructor(
    httpClient: HttpClient, 
    protected settingService: SettingsService,
    private customerManagementService: CustomerManagementService) {
    super(httpClient, settingService);
  }

   async shareReport(productflowid: string, emails: Array<string>, html:string, message: string): Promise<Observable<HttpResponse<any>>> {

    const contact = await this.customerManagementService.getCustomer().toPromise();

    let url =  this.settingService.getSettings().baseUrl + 'lightstone-productmanagement-webapi/' + this.settingService.getSettings().apiVersion + '/Flows/ShareProduct';
    let fullname = contact.firstName + ' ' + contact.lastName;
    let email = contact.emailAddress;         

    return this.post(
      url,{
         productFlowInstanceId: productflowid,
         emails: emails,
         html: html,
         message: message,
         sharerName: fullname,
         sharerCompanyName: contact.companyName,
         sharerEmail: email
     },''
     );

  }
  
  getReport(id:string): Observable<ShareModel> {
    return this.get(this.settingService.getSettings().baseUrl + 'lightstone-productmanagement-webapi/' + this.settingService.getSettings().apiVersion + '/Flows/GetSharedProduct/?id=' + id
    ).pipe(map(response => {
      return response.body;
    }));
  }

  exportReport(html:string):Promise<any>
  {
    return new Promise((resolve,reject)=>{
          this.HTMLtoPDF(html).subscribe({
            next: blob => {
                var newBlob = new Blob([blob], { type: "application/pdf" })
                const data = window.URL.createObjectURL(newBlob);
                var link = document.createElement('a');
                link.href = data;
                var filename = new Date().toISOString().slice(-24).replace(/\D/g,'').slice(0, 14) + ".pdf";
                link.download = filename;
                link.click();
                setTimeout(function () {
                    // For Firefox it is necessary to delay revoking the ObjectURL
                    window.URL.revokeObjectURL(data);
                    resolve({success:true});
                }, 100);
            }, error: error => {
                console.error(error);
                 
            }
        });
    });

    
  }

  public HTMLtoPDF(html: string): Observable<Blob> {
    const formData = new FormData();
    formData.append('html', html);
    var url = this.settingsService.getSettings().pdfUrl + "?n=" + Math.random();
    return this.httpClient.post(url, formData, { responseType: 'blob' });
}
}
