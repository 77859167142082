import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ShareService } from 'src/app/core/services/share/share.service';
import { SettingsService } from 'src/app/core/settings';

@Component({
  selector: 'app-downloadshare',
  templateUrl: './downloadshare.component.html',
  styleUrls: ['./downloadshare.component.scss']
})
export class DownloadshareComponent implements OnInit {
  logourl: string;
  sharerName: string;
  sharerEmail: string;
  downloading = true;

  constructor(
    private route: ActivatedRoute, 
    private router: Router,
    private shareService: ShareService,
    private settingService: SettingsService) { }

  ngOnInit(): void {

    this.logourl =  this.settingService.getSettings().cdnBaseUrl + '/Lightstone.Design/v1/images/logo.svg';

    this.route.queryParams.subscribe(param => {
      this.shareService.getReport(param.id).subscribe(share=>{
          this.sharerName = share.sharerName;
          this.sharerEmail = share.sharerEmail;
          this.shareService.exportReport(share.html).then(a=>{
            if(a.success){
              this.downloading = false;
            }
          });

      });
    });
  }

}
