import { Injectable } from '@angular/core';
import { of, Observable, defer } from 'rxjs';
import { Action } from '@ngrx/store';

@Injectable()
export class EventEffects {

  // @Effect()
  // init$: Observable<Action> = defer(() => {
  //   return  of({ type: 'NO_ACTION' });
  // });

  constructor() { }
}


