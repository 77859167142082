import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstLetter'
})
export class FirstLetterPipe implements PipeTransform {

  /*
	 * Transform
	 *
	 * @param value: any
	 * @param args: any
	 */
  transform(value: any, args?: any): any {
    return value.split(' ').map((n: string) => n[0]).join('');
  }
}
