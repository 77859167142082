export class EventServiceConnection{
    
    constructor(state?: EventServiceConnectionState, message?: string){
        this.state = state || this.state;
        this.message = message;
    }
    get connected(): boolean {
      return this.state === EventServiceConnectionState.CONNECTED;
    }
    message?: string;
    nextConnectionAttemptInSeconds?: number;
    state: EventServiceConnectionState = EventServiceConnectionState.DISCONNECTED;
    error?: any;
   
  }
  
  export enum EventServiceConnectionState
  {
    DISCONNECTED = 'Disconnected',
    CONNECTING = 'Connecting',
    CONNECTED = 'Connected',
    ERROR = 'Error',
    RECONNECTING = 'Reconnection'
  }
  