import { Component } from '@angular/core';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent {

  ErrorDescription: string;

  constructor() { 

    if(localStorage.getItem('ErrorDescription') != null)
      this.ErrorDescription = localStorage.getItem('ErrorDescription').split(":").join("<br>");
    else
      this.ErrorDescription = "An error occurred. Please try again later."; 
  }

}
