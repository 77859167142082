import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { MsalService } from '@azure/msal-angular';

@Injectable({
    providedIn: 'root'
})

export class LsAuthService {


    constructor(private authService: MsalService,) { }

    Logout() {
        localStorage.removeItem("msal.idtoken");
        localStorage.removeItem("InitialLoadUrl");
        localStorage.removeItem('signedinEmail');
        localStorage.removeItem('hint_email');
        localStorage.removeItem('invitationHasBeenCanceledByAdmin');
        localStorage.removeItem('acceptInviteToken');
        this.authService.logoutRedirect({ idTokenHint: null });
    }

    getLoggedInUserDetails() {
        let account = this.authService.instance.getAllAccounts()[0];
        const token = localStorage.getItem('msal.idtoken');
        const jwtHelperService = new JwtHelperService();
        const jwt = jwtHelperService.decodeToken(token);
        return { jwt: jwt, account: account };
    }




}