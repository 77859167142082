export const environment = {
  production: true,
  ocpApimSubscriptionKey: '',
  apimBaseUrl: 'https://apis.lightstone.co.za/',
  apiVersion : 'v1',
  clientID: '42129a40-38b7-448a-9a58-11f733cd8d06',
  redirectUri: 'http://localhost:4200/',
  authority: 'https://login.lightstone.co.za/tfp/lsgb2c.onmicrosoft.com/B2C_1A_SignUpSignInLSG/',
  tenantId: '08a7dd8c-1b74-4aa9-86e4-a58a18de70f5',
  onboardingUrl: '',
  scoreIrrelavence: 1,
  cdnBaseUrl: 'https://cdn.toolkit.lightstone.co.za/',
  version: {
    full: 'prod'
  },
  appInsights: {
    instrumentationKey: '164af70f-f90d-4898-9c87-4a10f0391cc4'
  },
  pdfUrl: 'http://pdf.lightstoneproperty.co.za/pdfconvert/convert.aspx',
  unauthenticatedKey: '',
  v1Url : 'https://toolkit.lightstoneproperty.co.za'
};
