import { Injectable } from '@angular/core';
import { BaseService } from '../../app/core/services/base.service';
import { IWorkflowService } from '../../app/core/services/workflow/workflowservice.models';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { SettingsService } from '../../app/core/settings';
import { Observable } from 'rxjs';
import { Referral } from '../../referral/models/referral.model';

@Injectable({
  providedIn: 'root'
})
export class ReferralWorkflowService extends BaseService implements IWorkflowService {

  startReferral(referral: Referral): Observable<HttpResponse<any>> {
    return this.put(
      this.settingService.getSettings().baseUrl + 'lightstone-referralmanagement-api/' + this.settingService.getSettings().apiVersion + '/Referral',
      referral);
  }

  constructor(httpClient: HttpClient, protected settingService: SettingsService) {
    super(httpClient, settingService);
  }
  public updateData(id: string, partyId: string, data: string): Observable<HttpResponse<any>> {
    return this.post(
      this.settingService.getSettings().baseUrl + 'lightstone-referralmanagement-api/' +
      this.settingService.getSettings().apiVersion + '/Referral/' + id + '/Output?partyId=' + partyId, {
      Data: JSON.stringify(data)
    }, '');
  }

  public completeInput(id: string, data: string, productCode?: string): Observable<HttpResponse<any>> {
    return this.post(
      this.settingService.getSettings().baseUrl + 'lightstone-referralmanagement-api/' +
      this.settingService.getSettings().apiVersion + '/Referral/' + id + '/Input/Complete' + (productCode ? ('?productCode=' + productCode) : ''), data, '');
  }

  public clientConsent(id: string): Observable<HttpResponse<any>>{
    return this.post(
      this.settingService.getSettings().baseUrl + 'lightstone-referralmanagement-api/' + 
      this.settingService.getSettings().apiVersion + '/Referral/' + id + `/Process?Subscription-Key=${this.settingService.getSettings().unauthenticatedKey}`, 
      {}, 
      '',
      null,
      true);
    
  }

}