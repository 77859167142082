import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { SettingsService } from '../../settings/settings.service';
import { Store } from '@ngrx/store';
import { AppState } from '../..';
import { BaseService } from '../../services/base.service';
import { map } from 'rxjs/operators';
import { ProductHistoryModel } from '../models/product-history.model';
import { ProductFlowStatus } from '../models/productFlowStatus.enum';

@Injectable({
  providedIn: 'root'
})
export class ProductService extends BaseService {

  private baseUrl;
  private apiVersion;


  private proccessStatus(status: number): string {

    if (Object.values(ProductFlowStatus).includes(status))
      return (ProductFlowStatus[status] ?? "Unknown").replace('_', ' ');
    else 
      return `Unknown status (${status})`;
  }

  constructor(private client: HttpClient, protected settingsService: SettingsService, private store: Store<AppState>) {
    super(client, settingsService);
    this.baseUrl = settingsService.getSettings().baseUrl;
    this.apiVersion = settingsService.getSettings().apiVersion;
  }

  public getProducts(): Observable<any> {

    return this.get(this.baseUrl + 'lightstone-productmanagement-webapi/' + this.apiVersion + '/Product/Get', null).pipe(map(res => {
      return res.body;
    }));
  }


  public getFlow(id: string): Observable<any> {
    return this.get(this.baseUrl + 'lightstone-productmanagement-webapi/' + this.apiVersion + '/Flows/' + id).pipe(map(res => {
      return res.body;
    }));
  }

  public getPurchaseHistory(): Observable<any> {
    return this.get(this.baseUrl + 'lightstone-productmanagement-webapi/' + this.apiVersion + '/Flows/PurchaseHistory', null).pipe(map(res => {
      return res.body.map(body => {
        return {
          created: body.created,
          expiryTimestamp: body.expiryTimestamp,
          id: body.id,
          productFlowId: body.productFlowId,
          productCode: body.productCode,
          productDescription: body.productDescription,
          productName: body.productName,
          status: this.proccessStatus(body.status),
        } as ProductHistoryModel;
      });
    }));
  }
}
