import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ReferralWorkflowService } from './services/referral.workflow.service';

@Component({
  selector: 'ls-referral',
  template: `<div class="d-flex justify-content-center loader-overlay">
  <div class="ls-loader"></div>
      <span class="visually-hidden">We are busy processing your request</span>
</div>`
})
export class ReferralComponent implements OnInit {
 
    constructor(private route: ActivatedRoute, private router: Router, private referralService: ReferralWorkflowService) {

    }

    ngOnInit(): void {
      this.route.params.subscribe(params => {        
        this.referralService.clientConsent(params.id).subscribe(response => {
          switch(response.body.type) {
            case 2: window.location.href = response.body.additionalData; break;
            case 1:
            case 0:
            default:
          }            
        });
      });
    }    
}

