import { NgModule, ErrorHandler } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app/app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { reducers, metaReducers, HttpErrorInterceptor } from './core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SettingsService } from './core/settings';
import { BrowserModule } from '@angular/platform-browser';
import { LightstoneOnboardingModule } from '@lightstone/onboarding';
import {​​​ IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel }​​​ from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent, MsalGuardAuthRequest } from '@azure/msal-angular';

import { DownloadServiceFactory } from './core/download/download.factory';
import { ErrorHandlerService } from './core/monitoring/errorHandler.service';
import { MonitoringService } from './core/monitoring/monitoring.service';
import { PDFDownloadProgressComponent } from './core/download/pdf/pdf-download-progress-component';
import { AcceptInviteComponent } from './partials/accept-invite/accept-invite.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ReferralModule } from 'src/referral/referral.module';
import { OnsellProgressComponent } from './core/product/onsell/onsell-progress.component';
import { EventService } from '@lightstone/config';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function MSALInstanceFactory(): IPublicClientApplication {

  return new PublicClientApplication({
    auth: {
      clientId: environment.production ? window['jupiter'].clientID : environment.clientID,
      authority: b2cConfig.authority.login,
      knownAuthorities: ["login.lightstone.co.za"],
      redirectUri: window['jupiter'].redirectUri,
      postLogoutRedirectUri: window['jupiter'].logoutRedirectUri,
      navigateToLoginRequestUrl: true
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });

}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}


export class b2cAuthority
{
    login = environment.production ? window['jupiter'].authority : environment.authority;
    get passwordReset()
    {
      return this.login.toLocaleLowerCase().replace('b2c_1a_signupsigninlsg', 'b2c_1a_passwordchange');
    }
}

export  class b2cConfig
{
    static clientId = environment.production ? window['jupiter'].clientID : environment.clientID;
    static authority = new b2cAuthority();
    static scopes =  ["openid", "profile"];
}

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  var request = null as MsalGuardAuthRequest;
  var url = window.location.href.toLowerCase();
  if (url.indexOf('email=') != -1) {
    var email = url.split('email=')[1].split("&")[0];
    request = {
      loginHint: email
    }
  }else{
    if(localStorage.getItem('hint_email') || localStorage.getItem('signedinEmail')){
      request = {
        loginHint: localStorage.getItem('hint_email') ?? localStorage.getItem('signedinEmail')
      }
  }
  }
  

  return { 
    interactionType: InteractionType.Redirect,
    authRequest: request
   };
}

@NgModule({
  declarations: [
    AppComponent,
    PDFDownloadProgressComponent,
    OnsellProgressComponent,
    AcceptInviteComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    HttpClientModule,
    MsalModule,
    NgbModule,
    FontAwesomeModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot({ stateKey: 'router' }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    LightstoneOnboardingModule,
    NgxPermissionsModule.forRoot(),
    ReferralModule,
    MsalModule
  ],
  entryComponents: [PDFDownloadProgressComponent, OnsellProgressComponent],
  providers: [
    MsalService,
    {
      provide: 'SettingsModel',
      useFactory: (settingsService: SettingsService) => {
        return settingsService.getSettings();
      },
      deps: [SettingsService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: DownloadServiceFactory,
      useClass: DownloadServiceFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    { provide: ErrorHandler, useClass: ErrorHandlerService }
    ,
    { provide: MonitoringService, useClass: MonitoringService }
    ,
    { provide: PDFDownloadProgressComponent, useClass: PDFDownloadProgressComponent },
    
    MsalGuard,
    MsalBroadcastService,
    EventService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
