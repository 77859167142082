import { Action } from '@ngrx/store';
import { IEventModel } from '../../models/event.model';

export enum eventActionTypes {
  SetSelectedEventItem = '[Event] SetSelectedeventItem',
  ClearEvent = '[Event] ClearEventResults',
  SetCorrelationId = '[Event] SetCorrelationId',
  SetCorrelatedEventItem = '[Event] SetCorrelatedEventItem',
}

export class SetSelectedeventItem implements Action {
  readonly type = eventActionTypes.SetSelectedEventItem;
  constructor(public payload: { event: IEventModel }) { }
}
export class SetCorrelatedEventItem implements Action {
  readonly type = eventActionTypes.SetCorrelatedEventItem;
  constructor(public payload: { event: IEventModel }) { }
}

export class ClearEvent implements Action {
  readonly type = eventActionTypes.ClearEvent;
  constructor(public payload: {}) { }
}

export class SetCorrelationId implements Action {
  readonly type = eventActionTypes.SetCorrelationId;
  constructor(public payload: { correlationId: string}) { }
}

export type eventActions = SetSelectedeventItem | ClearEvent | SetCorrelationId | SetCorrelatedEventItem;
