import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VettingService {

    pendingContractUpdate: Subject<boolean> = new Subject<boolean>();
    refreshVattingState: Subject<boolean> = new Subject<boolean>();
    dashboardLoading: Subject<boolean> = new Subject<boolean>();
 
}
