<div class="bs-docs-section ls-onboarding">
    <div class="bs-component">
        
        <div class="row m-5">

            <div class="col">
                <div class="card text-center p-5">
                    <img src="{{this.logourl}}" class="w-50" style="margin:auto">
                    <div class="p-3">
                        <p>
                            This report was shared by {{this.sharerName}}
                        </p>
                       
                    </div>
                    <div [hidden]="!downloading"> 
                        <p> Please wait while we download your report</p>
                        <div  class="bs-component mt-5 ml-0 mr-5">
                            <div class="ls-loader">
                                
                              </div>
                              <div>
                                <span class="visually-hidden">We are busy processing your request</span>
                              </div>
                        </div>
                        
                    </div>
                    <div [hidden]="downloading"> 
                        <h3>Your download is complete. We hope you enjoy your report.</h3>
                        
                    </div>
                 
                </div>
            </div>
        </div>
    </div>
 
</div>
