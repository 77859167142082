import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SettingsService } from '../settings';

export class BaseService {
  public httpClient: HttpClient;

  constructor(httpCllient: HttpClient, protected settingsService: SettingsService) {
    this.httpClient = httpCllient;
  }

  protected getHeaders(bearerToken, headers: HttpHeaders, unauthenticated = false): HttpHeaders {

    const key = this.settingsService.getSettings().apiKey;
    const tenantId = this.settingsService.getSettings().tenantId;

    if (headers === null) {
      headers = new HttpHeaders();
    }

    if (!unauthenticated) {
      headers = headers.set('Ocp-Apim-Subscription-Key', key);
    }
    
    headers = headers.set('Content-Type', 'application/json');
    headers = headers.set('x-Authenticated-TenantId', tenantId);
    return headers;
  }

  protected get(url: string, bearerToken: string = '', headers: HttpHeaders = new HttpHeaders()): Observable<HttpResponse<any>> {
    return this.httpClient.get<HttpResponse<any>>(url, { headers: this.getHeaders(bearerToken, headers), observe: 'response' });
  }

  protected post(url: string, data: any, bearerToken: string = '', headers: HttpHeaders = null, unauthenticated = false): Observable<HttpResponse<any>> {
    return this.httpClient.post<HttpResponse<any>>(url, data, { headers: this.getHeaders(bearerToken, headers, unauthenticated), observe: 'response' });
  }

  protected put(url: string, data: any, bearerToken: string = '', headers: HttpHeaders = null): Observable<HttpResponse<any>> {
    return this.httpClient.put<HttpResponse<any>>(url, data, { headers: this.getHeaders(bearerToken, headers), observe: 'response' });
  }

  protected delete(url: string, bearerToken: string = '', headers: HttpHeaders = null): Observable<HttpResponse<any>> {
    return this.httpClient.delete<HttpResponse<any>>(url, { headers: this.getHeaders(bearerToken, headers), observe: 'response' });
  }
}
