import { Action } from '@ngrx/store';
import { ProductHistoryModel } from '../../product/models/product-history.model';
import { AcceptInviteResponseModel } from '../../models/acceptInviteResponse.model';
export enum AcceptInviteActionTypes {
  AcceptInvite= '[accept-invite] AcceptInvite',
  AcceptInviteSuccess = '[accept-invite] AcceptInviteSuccess',
  AcceptInviteError = '[accept-invite] AcceptInviteError',
  SetAcceptInviteResponse = '[accept-invite] SetAcceptInvite',
  ClearInvite = '[accept-invite] Clear'
}

export class SetAcceptInviteResponse implements Action {
  readonly type = AcceptInviteActionTypes.SetAcceptInviteResponse;
  constructor(public payload: { acceptInviteResponse: AcceptInviteResponseModel }) { }
}

export class AcceptInvite implements Action {
  readonly type = AcceptInviteActionTypes.AcceptInvite;
  constructor(public payload: { token: string }) { }
}
export class AcceptInviteSuccess implements Action {
  readonly type = AcceptInviteActionTypes.AcceptInviteSuccess;
  constructor(public payload: { response: any }) { }
}
export class AcceptInviteError implements Action {
  readonly type = AcceptInviteActionTypes.AcceptInviteError;
  constructor(public payload: { error: any }) { }
}


export class ClearInvite implements Action {
  readonly type = AcceptInviteActionTypes.ClearInvite;
  constructor() { }
}

export type AcceptInviteActions = SetAcceptInviteResponse | AcceptInvite | ClearInvite | AcceptInviteSuccess | AcceptInviteError;
