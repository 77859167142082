<div class="modal-body">
  <div class="row">
    <div class="col mx-auto loader-overlay">
      <span class="ls-loader">
       
      </span>
      <span class="visually-hidden">We are busy processing your request</span>
  </div>
    </div>
</div>
