import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SettingsService } from '../../settings';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IRolesModel } from '../../models/rolesPermission.model';

@Injectable({
  providedIn: 'root'
})
export class RolesPermissionService extends BaseService {
  constructor(httpClient: HttpClient, protected settingService: SettingsService) {
    super(httpClient, settingService);
  }

  getRolesAndPermissions(): Observable<IRolesModel[]> {
    return this.get(this.settingService.getSettings().baseUrl + 'customer-management/' + this.settingService.getSettings().apiVersion + '/customer/GetUserRolesAndPermissions').pipe(map(response => {
      return response.body;
    }));
  }
}
