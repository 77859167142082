import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from '../../settings';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CannyService extends BaseService {
  constructor(httpClient: HttpClient, protected settingService: SettingsService) {
    super(httpClient, settingService);
  }
  getToken(data: any): Observable<string> {
    return this.post(
      this.settingService.getSettings().baseUrl + 'customer-management/' + this.settingService.getSettings().apiVersion + '/canny/token',
      data,
      ''
    ).pipe(map(response => {
        return response.body.token;
    }));
  }

}
