import { IDownloadService } from './download.models';
import { PdfDownloadService } from './pdf/pdf.download.service';
import { Injectable, Injector } from '@angular/core';
import { SettingsService } from '../settings';
import { ExcelDownloadService } from './excel/excel.download.service';
import { ShareDownloadService } from './share/share.download.service';
@Injectable({
    providedIn: 'root',
  })
export class DownloadServiceFactory {


    constructor(private injector: Injector,  protected settingsService: SettingsService){

    }
    public init():void {
        this.downloadServices.forEach((service) => service.init());
    }

    public destroy(): void {
        this.downloadServices.forEach((service) => service.destroy());
    }
    private _downloadServices = [new PdfDownloadService(this.injector, this.settingsService), new ExcelDownloadService(this.injector, this.settingsService), new ShareDownloadService(this.injector, this.settingsService)];
    public get downloadServices(): IDownloadService[] {
        return this._downloadServices;
    } 
}