import { IDownloadService, DownloadEvent } from '../download.models';
import { fromEvent, Subscription, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PDFDownloadProgressComponent } from '../pdf/pdf-download-progress-component';
import { SettingsService } from '../../settings';
import { filter, map } from 'rxjs/operators';

export class ExcelDownloadService implements IDownloadService {
    private sub: Subscription;
    private excelServerUrl: string;
    headers:any;
    private httpClient: HttpClient;
    private document: Document;
    private modalService: NgbModal;
    constructor(
        private injector: Injector, protected settingsService: SettingsService
    ) {
        this.httpClient = this.injector.get(HttpClient);
        this.document = this.injector.get(DOCUMENT);
        this.modalService = this.injector.get(NgbModal);
        this.excelServerUrl = `${this.settingsService.getSettings().baseUrl}/lightstone-capabilities-export-api/${this.settingsService.getSettings().apiVersion}/ConvertJson`;
    }

    public exportToExcel(data: object, template: string): Observable<Blob> {
        this.headers = {
            'Output': 'xlsx',
            'Content-Type': 'application/json'
        };
       return this.httpClient.post(this.excelServerUrl + '/' + template, data, { headers: this.headers, responseType: 'blob'});
    }

    init(): void {
        this.sub = fromEvent<DownloadEvent>(this.document, 'exportToExcel').pipe(filter(e => e.detail && e.detail.type && e.detail.type === 'xlsx')).subscribe((e) => {
            const modalRef = this.modalService.open(PDFDownloadProgressComponent);
            this.exportToExcel(e.detail.data, e.detail.template).subscribe({
                next: blob => {
                    var newDate = new Date();
                    var newBlob = new Blob([blob], { type: "application/octet-stream" })
                    const data = window.URL.createObjectURL(newBlob);
                    var link = document.createElement('a');
                    link.href = data;
                    link.download = `${newDate.getDate()}-${newDate.getMonth()+1}-${newDate.getFullYear()}-${newDate.getTime()}.xlsx`
                    link.click();
                    setTimeout(function () {
                        // For Firefox it is necessary to delay revoking the ObjectURL
                        window.URL.revokeObjectURL(data);
                        modalRef.close();
                    }, 100);
                }, error: error => {
                    console.error(error);
                    modalRef.close();
                }
            });
        });
    }
    destroy(): void {
        this.sub.unsubscribe();
    }
}