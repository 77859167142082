<div class="row">
    <div class="col text-center p-4 bg-light">
        <img src="../../../assets/images/LoginError.png" alt="">
        <p class="mt-3">
            It appears that you have encountered an authentication error.<br>
            Please contact your administrator for assistance.
        </p>
        <p>
            <b>Error Details</b>: <span [innerHTML]="ErrorDescription"> </span>
        </p>
        <p>
            We apologize for any inconvenience.
        </p>
    </div>
</div>
