import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { eventReducer } from './store/event/_reducers';
import { FirstLetterPipe } from './pipes/first-letter.pipe';
import { SettingsService } from './settings';
import { ProductService } from './product/service/product.service';
import { EventService } from './services/event/event.service';
import { EventEffects } from './store/event/_effects';
import { WebComponentLoaderService } from './services/webcomponentloader/webcomponentloader.service';
import { ProductFlowWorkflowService } from './services/workflow/productflow.workflow.service';
import { CannyService } from './services/canny/canny-token.service';
import { acceptInviteReducer } from './store/accept-invite/_reducers';
import { AcceptInviteEffects } from './store/accept-invite/_effects';
import { AcceptInviteService } from './services/accept-invite/accept-invite.service';
import { ReferralWorkflowService } from '../../referral/services/referral.workflow.service';
import { WorkflowServiceFactory } from './services/workflow/workflowservicefactory.service';
import { SortableDirective } from './directives/sortable.directive';
import { LsAuthService } from './services/auth-behavior/lsauth.service';
import { OnboardingService } from './services/onboarding/onboarding.service';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('events', eventReducer),
    EffectsModule.forFeature([EventEffects]),
    StoreModule.forFeature('accept-invite', acceptInviteReducer),
    EffectsModule.forFeature([AcceptInviteEffects])
  ],
  exports: [
    FirstLetterPipe,
    SortableDirective
  ],
  declarations: [
  FirstLetterPipe,
  SortableDirective],
  providers: [
    SettingsService,
    ProductService,
    EventService,
    WebComponentLoaderService,
    ProductFlowWorkflowService,
    ReferralWorkflowService,
    WorkflowServiceFactory,
    CannyService,
    AcceptInviteService,
    LsAuthService,
    OnboardingService
  ]
})
export class CoreModule { }
