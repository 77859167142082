import { Injectable } from '@angular/core';
import { IWorkflowService, WorkflowKind } from './workflowservice.models';
import { ProductFlowWorkflowService } from './productflow.workflow.service';
import { ReferralWorkflowService } from '../../../../referral/services/referral.workflow.service';

@Injectable({
    providedIn: 'root'
  })
  export class WorkflowServiceFactory {
      constructor(
          private productFlowWorkflowService: ProductFlowWorkflowService,
          private referralWorkflowService: ReferralWorkflowService
        ) {          
      }
      public getServiceForWorkflowKind(kind :WorkflowKind): IWorkflowService{
          switch (kind) {
              case WorkflowKind.ProductFlow:
                  return this.productFlowWorkflowService;
              case WorkflowKind.Referral:         
                return this.referralWorkflowService;
              default:
                  break;
          }
      }
  }