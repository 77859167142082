import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SettingsService } from '../../settings';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AcceptInviteResponseModel } from '../../models/acceptInviteResponse.model';

@Injectable({
  providedIn: 'root'
})
export class AcceptInviteService extends BaseService {
  constructor(httpClient: HttpClient, protected settingService: SettingsService) {
    super(httpClient, settingService);
  }

  acceptInvite(token: string): Observable<AcceptInviteResponseModel> {
    console.log(token['payload'].token);
    return this.get(this.settingService.getSettings().baseUrl + 'customer-management/' + this.settingService.getSettings().apiVersion + '/customer/acceptinvite?token=' + token['payload'].token
    ).pipe(map(response => {
      localStorage.removeItem('hint_email');
      localStorage.removeItem('invitationHasBeenCanceledByAdmin');
      let results = response.body;
      results["accepted"] = "false";
      results["invitationCompleted"] = false;
      return results;
    }));
  }

 
}
