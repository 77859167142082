import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AcceptInviteComponent } from './partials/accept-invite/accept-invite.component';
import { DownloadshareComponent } from './partials/share/downloadshare.component';
import { ErrorComponent } from './pages/error/error.component'; 

const routes: Routes = [
  {
    path: 'customer',
    canActivate: [MsalGuard],
    loadChildren: () => import('./pages/customer/customer-page.module').then(m => m.CustomerPageModule)
  },
    {    path: 'referral',
    loadChildren: () => import('../referral/referral.module').then(m => m.ReferralModule)
  },
  {
    path: 'acceptinvite',
    component: AcceptInviteComponent
  },
  {
    path: 'share',
    canActivate: [MsalGuard],
    component: DownloadshareComponent
  },
  {
    path: 'error',
    component: ErrorComponent
  }
  ,
  {
    path: '',
    canActivate: [MsalGuard],
    loadChildren: () =>
      import('./pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: '**',
    canActivate: [MsalGuard],
    redirectTo: ''
  }
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { 
    useHash: true, 
    onSameUrlNavigation: 'reload', 
    urlUpdateStrategy: 'eager'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

