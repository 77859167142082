import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { IJupiterSettings } from './jupiterSettings.model';



@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  getSettings(): IJupiterSettings {

    const baseUrl = environment.production ? window['jupiter'].apimBaseUrl : environment.apimBaseUrl;
    const apiVersion = environment.production ? window['jupiter'].apiVersion : environment.apiVersion;
    const ocpKey = environment.production ? window['jupiter'].ocpApimSubscriptionKey : environment.ocpApimSubscriptionKey;
    const tenantId = environment.production ? window['jupiter'].tenantId : environment.tenantId;
    const onboardingUrl = environment.production ? window['jupiter'].onboardingUrl : environment.onboardingUrl;
    const scoreIrrelavence = environment.production ? window['jupiter'].scoreIrrelavence : environment.scoreIrrelavence;
    const version = environment.production ? window['jupiter'].version.full : environment.version.full;
    const cdnBaseUrl = environment.production ? window['jupiter'].cdnUrl : environment.cdnBaseUrl;
    const pdfUrl = environment.production ? window['jupiter'].pdfUrl : environment.pdfUrl;
    const v1Url = environment.production ? window['jupiter'].v1Url : environment.v1Url;
    const unauthenticatedKey = environment.production ? window['jupiter'].unauthenticatedKey : environment.unauthenticatedKey;
    const token = localStorage.getItem('msal.idtoken');
    const authority = window['jupiter'].authority;
    const search = window['jupiter'].search;
    const jwtHelperService = new JwtHelperService();
    const jwt = jwtHelperService.decodeToken(token);
    
    return {
      apiKey: ocpKey,
      baseUrl,
      apiVersion,
      tenantId,
      scoreIrrelavence,
      version,
      cdnBaseUrl,
      pdfUrl,
      unauthenticatedKey,
      partyId:jwt? jwt.sub : '',
      authority,
      search,
      v1Url,
      onboardingUrl
    } as IJupiterSettings;
  }

  getEmail():string{
    const token = localStorage.getItem('msal.idtoken');
    const jwtHelperService = new JwtHelperService();
    const jwt = jwtHelperService.decodeToken(token);
    const email = jwt?jwt.email : '';
    
    return email;
  }

  getTokenExpiryDate():Date{
    const token = localStorage.getItem('msal.idtoken');
    const jwtHelperService = new JwtHelperService();
    return jwtHelperService.getTokenExpirationDate(token);
  }
}
