export enum ProductFlowStatus {
        New = 0,
        Inputs_Required = 1,
        Awaiting_Quote = 2,
        Awaiting_Payment = 3,
        Quote_Rejected_Vintage = 4, 
        Awaiting_Processing = 5,
        Completed = 6,
        Payment_Success = 7,
        Await_Legal = 8,
        Received_Legal = 9,
        Start_Rejected = 901,
        Start_Error = 1909,
        Validate_Rejected = 911,
        Validate_Error = 1910,
        DuplicateDetection_Error = 1920,
        Legal_Rejected = 931,
        Legal_Error = 1930,
        Quote_Rejected = 941,
        Quote_Error = 1940,
        Payment_Rejected = 951,
        Payment_Error = 1950,
        Process_Rejected = 961,
        Process_Error = 1960,
}